import React from 'react'
import SchoolInfosNavigation from '../../../components/Infos/SchoolInfos/SchoolInfosNavigation'
import SchoolLayout from '../../../components/schoollayout'
import BigButton from '../../../components/Ui/BigButton'

const SchoolKitPromo = () => (
    <SchoolLayout>
        <div className='school-preparation'>

            <SchoolInfosNavigation />

            <h2>Kit promo</h2>

            <div className='school-preparation-inner'>

                <div className="mt-10 relative h-[35rem]">
                    <iframe
                        title="Le Festif! à l'école 🎶🚗"
                        className="absolute top-0 left-0 w-full h-full mb-3rem"
                        src={'https://www.youtube.com/embed/oOZD7t4oDW4'}
                        allowFullScreen=""
                        referrerPolicy="origin-when-cross-origin"
                        height={'100%'}
                    ></iframe>
                </div>

                <div className='pt-12 flex justify-center w-full text-center'>
                    <div className="flex justify-around w-3/4">
                        <BigButton text="Affiche Web" largeText={false} className="w-1/3" innerClassName="!bg-blue-school !text-white mx-auto" target="_blank" href="https://drive.google.com/file/d/1Y4xXgNhtwBuYA72YtmZhLhM9crEcTvCF/view?usp=drive_link" />
                        <BigButton text="Affiche pour écran" largeText={false} className="w-1/3" innerClassName="!bg-blue-school !text-white mx-auto" target="_blank" href="https://drive.google.com/file/d/18-cJtukpP2aSEFJQt8LnYeSQFF2yR9oW/view?usp=drive_link" />
                        <BigButton text="Affiche à imprimer" largeText={false} className="w-1/3" innerClassName="!bg-blue-school !text-white mx-auto" target="_blank" href="https://drive.google.com/file/d/1fyq8Xlm2RL3Q6_8uWu9y3zWH7qaa6DlE/view?usp=drive_link" />
                    </div>
                </div>

            </div>

        </div>
    </SchoolLayout>
)

export default SchoolKitPromo
